import React, { useState } from 'react'
import { Button, Form, Input, Tabs, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import request from 'utils/request'

import styles from './index.module.scss'

const Login = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [sendText, setSendText] = useState('发送验证码')
    let countdownNum = 60

    const onFinish = async (value) => {
        const res = await request('admin/login', { data: value })
        if (res.statusCode === 200) {
            messageApi.success('登录成功')
            navigate('/')
        } else {
            messageApi.error(res?.message || '登录失败')
        }
    }

    const onCodeFinish = async (value) => {
        const res = await request('admin/codeLogin', { data: value })
        if (res.statusCode === 200) {
            messageApi.success('登录成功')
            navigate('/')
        } else {
            messageApi.error(res?.message || '登录失败')
        }
    }

    const onChange = () => {}

    // 倒计时
    const countdown = () => {
        setTimeout(() => {
            if (countdownNum > 0) {
                countdownNum--
                setSendText(`重新发送(${countdownNum}秒)`)
                countdown()
            } else {
                countdownNum = 60
                setSendText('重新发送')
            }
        }, 1000)
    }

    // 发送验证码
    const send = async () => {
        try {
            if (sendText !== '发送验证码' && sendText !== '重新发送') {
                messageApi.warning('别急别急～')
                return
            }

            const phone = form.getFieldValue('phone')

            if (!phone) {
                messageApi.error('请先输入手机号')
                return
            }

            const res = await request('sendSmsCode', { method: 'GET', params: { phone, channel: 'muyu-admin' } })

            if (res.statusCode === 200) {
                // 倒计时
                countdown()
            } else {
                messageApi.error(res?.message || '发送失败')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const items = [
        {
            key: '1',
            label: '账号密码',
            children: (
                <Form className={styles.form} onFinish={onFinish}>
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '请输入您的手机号'
                            }
                        ]}>
                        <Input placeholder="请输入您的手机号" prefix={<UserOutlined />} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的密码'
                            }
                        ]}>
                        <Input.Password placeholder="请输入你的密码" prefix={<LockOutlined />} />
                    </Form.Item>
                    <div className={styles.controls}>
                        <b className={styles.link} onClick={() => navigate('/signin')}>
                            申请账号
                        </b>
                        <b className={styles.link} onClick={() => navigate('/forget')}>
                            忘记密码
                        </b>
                    </div>
                    <Form.Item>
                        <Button type="primary" className={styles.loginBtn} htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            )
        },
        {
            key: '2',
            label: '手机验证码',
            children: (
                <Form form={form} className={styles.form} onFinish={onCodeFinish}>
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '请输入您的手机号'
                            }
                        ]}>
                        <Input placeholder="请输入您的手机号" prefix={<UserOutlined />} />
                    </Form.Item>

                    <Form.Item
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: '请输入验证码'
                            }
                        ]}>
                        <Input
                            placeholder="请输入验证码"
                            className={styles.inputNumber}
                            addonAfter={
                                <div className={styles.send} onClick={() => send()}>
                                    {sendText}
                                </div>
                            }
                            controls={false}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" className={styles.loginBtn} htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            )
        }
    ]

    return (
        <>
            {contextHolder}
            <div className={styles.view}>
                <img className={styles.logo} src="./muyu-logo-title.png" />
                <p className={styles.desc}>Every Second Counts</p>

                <Tabs defaultActiveKey="1" centered tabBarGutter={30} items={items} onChange={onChange} />
            </div>
        </>
    )
}

export default Login
