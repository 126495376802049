import React, { useEffect, useState, useRef } from 'react'
import { Button, Table, message, Form, Input, Popconfirm, Card } from 'antd'
import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'

import request from 'utils/request'
import Detail from './detail'

const servesText = {
    'mini-shop': '小程序商城',
    wechat: '微信公众号'
}

const Merchants = () => {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()

    const detailRef = useRef()
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(10)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60
        },
        {
            title: '品牌名称',
            dataIndex: 'brandName',
            key: 'brandName',
            width: 200
        },
        {
            title: '服务项',
            dataIndex: 'serves',
            key: 'serves',
            width: 200,
            render: (text) => text.map((item) => servesText[item])?.join('、')
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (text) => {
                return text === 'active' ? '待服务' : text === 'pending' ? '服务中' : text === 'expired' ? '已过期' : text === 'terminated' ? '已禁用' : ''
            }
        },
        {
            title: '服务到期时间',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 200,
            render: (text) => (!!text ? dayjs(text).format('YYYY-MM-DD') : '暂无')
        },
        {
            title: '操作',
            width: 200,
            render: (_, rows) => (
                <>
                    <a onClick={() => detailRef.current.show(rows.id)}>编辑</a>
                    {rows.status === 'pending' && (
                        <Popconfirm placement="left" title="是否需要停止该商户的服务?" onConfirm={() => handleStatus(rows.id, 'terminated')}>
                            <a style={{ color: '#ff4d4f', marginLeft: 10 }}>终止服务</a>
                        </Popconfirm>
                    )}

                    {rows.status === 'expired' ||
                        (rows.status === 'terminated' && (
                            <Popconfirm placement="left" title="确定开启该商户服务吗?" onConfirm={() => handleStatus(rows.id, 'pending')}>
                                <a style={{ color: '#52c41a', marginLeft: 10 }}>开启服务</a>
                            </Popconfirm>
                        ))}
                </>
            )
        }
    ]

    const handleStatus = async (id, path) => {
        if (!path) return
        try {
            const res = await request(`merchants/${path}`, { method: 'POST', data: { id } })
            const { statusCode, message } = res
            if (statusCode === 200) {
                messageApi.success('操作成功')
                getList()
            } else {
                messageApi.error(message || '操作失败')
            }
        } catch (err) {
            console.log(err)
            messageApi.error(err || '操作失败')
        }
    }

    const getList = async (values) => {
        try {
            setLoading(true)
            const res = await request('merchants/list', {
                method: 'POST',
                data: {
                    currentPage: page,
                    pageSize: size,
                    ...values
                }
            })

            const {
                statusCode,
                data,
                page: { currentPage, pageSize, total }
            } = res

            if (statusCode === 200) {
                setDataSource(data)
                setPage(currentPage)
                setSize(pageSize)
                setTotal(total)
            } else {
                messageApi.error(res?.message?.[0] || '获取商户列表失败')
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <>
            {contextHolder}
            <Card style={{ marginBottom: 20 }}>
                <Form
                    form={form}
                    initialValues={{}}
                    layout="inline"
                    onFieldsChange={() => {
                        console.log('onFieldsChange')
                    }}
                    onValuesChange={(_, values) => {
                        getList(values)
                    }}>
                    <Form.Item label="关键词" name="keyword">
                        <Input placeholder="可输入标题" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                form.resetFields()
                                getList()
                            }}>
                            重置
                        </Button>
                        <Button type="primary" onClick={() => detailRef.current.show()}>
                            新建商户
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Card>
                <Table
                    rowKey={'id'}
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: [5, 10, 20, 50],
                        current: page,
                        pageSize: size,
                        total: total,
                        showTotal: (total) => `总数: ${total} 项`,
                        onChange: (current, size) => {
                            setSize(size)
                            setPage(current)
                        }
                    }}
                />
            </Card>

            <Detail ref={detailRef} />
        </>
    )
}

export default Merchants
