import { useState } from 'react'
import { Button, Form, Input, Divider, Radio, message } from 'antd'
import { useNavigate } from 'react-router-dom'

import request from 'utils/request'

import styles from './index.module.scss'

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

const Signin = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [sendText, setSendText] = useState('发送验证码')
    let countdownNum = 60

    // 倒计时
    const countdown = () => {
        setTimeout(() => {
            if (countdownNum > 0) {
                countdownNum--
                setSendText(`重新发送(${countdownNum}秒)`)
                countdown()
            } else {
                countdownNum = 60
                setSendText('重新发送')
            }
        }, 1000)
    }

    // 发送验证码
    const send = async () => {
        try {
            if (sendText !== '发送验证码' && sendText !== '重新发送') {
                messageApi.warning('别急别急～')
                return
            }

            const phone = form.getFieldValue('phone')

            if (!phone) {
                messageApi.error('请先输入手机号')
                return
            }

            const res = await request('sendSmsCode', { method: 'GET', params: { phone, channel: 'muyu-admin' } })

            if (res.statusCode === 200) {
                // 倒计时
                countdown()

                // 测试操作
                // form.setFieldValue('code', +res.data)
            } else {
                messageApi.error(res?.message || '发送失败')
            }
        } catch (err) {
            console.log(err)
        }
    }

    // 提交注册
    const onFinish = async (value) => {
        try {
            const res = await request('admin/signin', { data: value })

            if (res.statusCode === 200) {
                message.success('信息已提交,请等待审核')
                navigate('/login')
            } else {
                message.error(res?.message?.[0] || '注册信息提交失败')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {contextHolder}
            <div className={styles.view}>
                <p className={styles.desc}>～请填写你的账号信息～</p>
                <Form form={form} className={styles.form} {...layout} onFinish={onFinish}>
                    <Form.Item
                        label="真实姓名"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: '请输入真实姓名'
                            }
                        ]}>
                        <Input placeholder="请输入真实姓名" />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的密码'
                            }
                        ]}>
                        <Input.Password placeholder="请输入你的密码" />
                    </Form.Item>

                    <Form.Item
                        label="再次输入密码"
                        name="twoPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的密码'
                            },
                            {
                                required: true,
                                validator: (rule, value) => {
                                    const pw = form.getFieldValue('password')
                                    if (pw !== value) {
                                        return Promise.reject(new Error('请输入相同的密码!'))
                                    } else {
                                        return Promise.resolve()
                                    }
                                },
                                trigger: 'blur'
                            }
                        ]}>
                        <Input.Password placeholder="请输入你的密码" />
                    </Form.Item>

                    <Form.Item
                        label="权限身份"
                        name="roles"
                        rules={[
                            {
                                required: true,
                                message: '请选择你需要的权限身份'
                            }
                        ]}>
                        <Radio.Group>
                            <Radio value={1}>超级管理员</Radio>
                            <Radio value={2}>协作者</Radio>
                            <Radio value={3}>数据观察者</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="手机号"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '请输入您的手机号'
                            }
                        ]}>
                        <Input placeholder="请输入您手机号" />
                    </Form.Item>

                    <Form.Item
                        label="验证码"
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: '请输入验证码'
                            }
                        ]}>
                        <Input
                            placeholder="请输入验证码"
                            className={styles.inputNumber}
                            addonAfter={
                                <div className={styles.send} onClick={() => send()}>
                                    {sendText}
                                </div>
                            }
                            controls={false}
                        />
                    </Form.Item>

                    <p className={styles.tpic}>信息提交后请等待管理员审核,审核通过后即可登陆</p>

                    <div className={styles.btns}>
                        <Button type="primary" className={styles.btn} htmlType="submit">
                            注册
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Signin
