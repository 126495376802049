import { CoffeeOutlined, UserOutlined, HomeOutlined, ShoppingOutlined } from '@ant-design/icons'

const menuItems = [
    {
        key: '',
        icon: <CoffeeOutlined />,
        label: '欢迎',
        roles: [1, 2, 3]
    },
    {
        key: 'index',
        icon: <HomeOutlined />,
        label: '官网配置',
        roles: [1, 2, 3],
        children: [
            {
                key: 'include',
                label: '收录信息',
                roles: [1, 2, 3]
            }
        ]
    },
    {
        key: 'merchants',
        icon: <ShoppingOutlined />,
        label: '合作商户',
        roles: [1, 2, 3]
        // children: [
        //     {
        //         key: 'merchants',
        //         label: '商户列表',
        //         roles: [1, 2, 3]
        //     }
        // ]
    },
    {
        key: 'account',
        icon: <UserOutlined />,
        label: '账号管理',
        roles: [1, 2, 3],
        children: [
            {
                key: 'audit',
                label: '待审核账号',
                roles: [1, 2, 3]
            },
            {
                key: 'info',
                label: '账号信息',
                roles: [1, 2, 3]
            }
        ]
    }
]

export default menuItems
