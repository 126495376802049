import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'
import { RouterProvider } from 'react-router-dom'
import router from 'router'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

import './index.css'

import reportWebVitals from './reportWebVitals'

dayjs.extend(weekday)
dayjs.extend(localeData)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <ConfigProvider
            locale={zhCN}
            theme={{
                cssVar: true,
                token: {
                    colorLink: '#fa992f',
                    colorPrimary: '#fa992f'
                },
                components: {
                    Menu: {
                        // lineWidth: 0,
                        itemBg: 'transparent;',
                        subMenuItemBg: 'transparent;',
                        itemColor: 'rgba(0, 0, 0, 0.65)',
                        itemSelectedColor: '#000',
                        itemSelectedBg: 'rgba(0, 0, 0, 0.04)'
                    }
                }
            }}>
            <RouterProvider router={router} />
        </ConfigProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
