import React from 'react'
import { Card, Alert } from 'antd'

import styles from './index.module.scss'

const Welcome = () => {
    return (
        <Card className={styles.view}>
            <Alert message="这个世界上没有分好的工作和坏的工作,有帮助到别人的就是了不起的工作" type="success" showIcon />
            <h1>Welcome~</h1>
        </Card>
    )
}

export default Welcome
