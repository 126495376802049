import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, Button, message, DatePicker, Form, Input, Space } from 'antd'
import dayjs from 'dayjs'

import request from 'utils/request'
import { Select } from 'antd'

const Detail = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [initialState, setInitialState] = useState({})
    const [id, setId] = useState(null)

    const layout = {
        labelCol: {
            span: 4
        },
        wrapperCol: {
            span: 18
        }
    }

    useImperativeHandle(ref, () => ({
        show: (propsId) => {
            !!propsId && setId(propsId)
            setVisible(true)
        }
    }))

    const getInitData = async () => {
        try {
            const res = await request('merchants/' + id, {
                method: 'GET'
            })

            const { statusCode, data } = res
            if (statusCode === 200) {
                // 将日期字符串转换为 dayjs 对象
                const formattedData = {
                    ...data,
                    endDate: !!data?.endDate ? dayjs(data.endDate) : null
                }
                setInitialState(formattedData)
                form.setFieldsValue(formattedData) // 更新表单的值
            }
        } catch {}
    }

    const onFinish = async (values) => {
        try {
            form.validateFields()
            const formattedValues = {
                ...values,
                endDate: values.endDate ? dayjs(values.endDate).valueOf() : null
            }
            !!id && (formattedValues.id = id)

            const res = await request('merchants/' + (id ? 'update' : 'create'), { data: formattedValues })

            const { statusCode } = res
            if (statusCode === 200) {
                setVisible(false)
                messageApi.success('提交成功')
            } else {
                messageApi.error(res?.message?.[0] || '提交失败')
            }
        } catch {}
    }

    useEffect(() => {
        if (visible) {
            !!id && getInitData()
        } else {
            setInitialState({})
            setId()
            form.resetFields()
        }
    }, [visible])

    return (
        <>
            {contextHolder}
            <Modal
                width={800}
                open={visible}
                title={id ? `商户信息(${id})` : '新建商户'}
                onCancel={() => setVisible(false)}
                footer={
                    <Space>
                        <Button type="primary" onClick={() => form.submit()}>
                            提交
                        </Button>
                        <Button htmlType="button" onClick={() => form.resetFields()}>
                            重置
                        </Button>
                        <Button htmlType="button" onClick={() => setVisible(false)}>
                            取消
                        </Button>
                    </Space>
                }>
                <Form form={form} {...layout} initialState={initialState} style={{ marginBottom: 20, paddingTop: 20 }} onFinish={onFinish}>
                    <Form.Item
                        name="name"
                        label="商户名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入商户名称!'
                            }
                        ]}>
                        <Input placeholder="请输入商户名称" />
                    </Form.Item>

                    <Form.Item
                        name="brandName"
                        label="品牌名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入品牌名称!'
                            }
                        ]}>
                        <Input placeholder="请输入品牌名称" />
                    </Form.Item>

                    <Form.Item name="appid" label="appid">
                        <Input placeholder="请输入appid" />
                    </Form.Item>

                    <Form.Item name="secret" label="secret">
                        <Input placeholder="请输入secret" />
                    </Form.Item>

                    <Form.Item name="contract" label="协议链接">
                        <Input placeholder="请输入协议链接" />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label="联系方式"
                        rules={[
                            {
                                required: true,
                                message: '请输入联系方式!'
                            }
                        ]}>
                        <Input placeholder="请输入联系方式" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="邮箱"
                        rules={[
                            {
                                required: true,
                                message: '请输入邮箱!'
                            }
                        ]}>
                        <Input placeholder="请输入邮箱" />
                    </Form.Item>

                    <Form.Item name="remark" label="备注">
                        <Input placeholder="备注" />
                    </Form.Item>

                    <Form.Item
                        name="serves"
                        label="服务项目"
                        rules={[
                            {
                                required: true,
                                message: '请选择服务项目!'
                            }
                        ]}>
                        <Select placeholder="请选择服务项目" mode="multiple" allowClear>
                            <Select.Option value="mini-shop">电商小程序</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="endDate" label="服务到期时间">
                        <DatePicker style={{ width: '100%' }} placeholder="请输入协议结束时间" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
})

export default Detail
