import React, { useState } from 'react'
import { Descriptions, Image, Card } from 'antd'
import { useAtomValue } from 'jotai'
import dayjs from 'dayjs'

import { adminInfoAtom } from 'store'

import styles from './index.module.scss'

const AccountInfo = () => {
    const adminInfo = useAtomValue(adminInfoAtom)
    const items = [
        {
            key: 0,
            label: 'ID',
            children: adminInfo.id
        },
        {
            key: 1,
            label: '头像',
            children: <Image className={styles.avatar} width={100} src={adminInfo.avatar} />
        },
        {
            key: 2,
            label: '姓名',
            children: adminInfo.name
        },
        {
            key: 3,
            label: '手机号',
            children: adminInfo.phone
        },
        {
            key: 4,
            label: '身份',
            children: adminInfo.roles === 1 ? '超级管理员' : adminInfo.roles === 2 ? '协作者' : adminInfo.roles === 3 ? '数据观察员' : '无'
        },
        {
            key: 5,
            label: '注册时间',
            children: dayjs(adminInfo.createDate).format('YYYY-MM-DD HH:mm:ss')
        }
    ]

    return (
        <Card>
            <Descriptions items={items} column={1} />
        </Card>
    )
}

export default AccountInfo
