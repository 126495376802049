import { useState, forwardRef, useImperativeHandle } from 'react'
import { Form, Modal, Input, Select, message } from 'antd'

import CUpdate from 'components/CUpdate'
import request from 'utils/request'

const IndexDetail = forwardRef(({ initialValues = {}, reload = () => {} }, ref) => {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [types, setTypes] = useState([])

    useImperativeHandle(ref, () => ({
        open: (data = {}) => {
            form.resetFields()
            onValuesChange({ category: data.category })
            data?.id &&
                form.setFieldsValue({
                    ...data,
                    image: [
                        {
                            uid: data.image,
                            name: data.image,
                            thumbUrl: data.image,
                            response: { data: data.image }
                        }
                    ]
                })

            setIsModalOpen(true)
        },
        close: () => setIsModalOpen(false)
    }))

    const getImageDimensions = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = src
            img.onload = () => {
                const width = img.naturalWidth
                const height = img.naturalHeight
                resolve({ width, height })
            }
            img.onerror = () => {
                reject(new Error('Failed to load image'))
            }
        })
    }

    const onFinish = async ({ title, desc, image, url, category, type }) => {
        try {
            const { width, height } = await getImageDimensions(image[0].thumbUrl)

            const id = form.getFieldValue('id')

            console.log('image', image, width, height)

            const data = {
                title,
                desc,
                url,
                image: image[0]?.response?.data,
                heightProportion: +(width / height).toFixed(2)
            }

            const res = id
                ? await request('index/save', {
                      method: 'POST',
                      data: {
                          ...data,
                          id
                      }
                  })
                : await request('index/create', {
                      data: {
                          ...data,
                          category,
                          type
                      }
                  })

            if (res.statusCode === 200) {
                messageApi.success('创建成功')
                setIsModalOpen(false)
                reload()
            } else {
                messageApi.error(res?.message || '创建失败')
            }
        } catch (err) {
            console.log(err)
            messageApi.error('创建失败')
        }
    }

    const onValuesChange = (value) => {
        const category = value.category
        if (!category) return

        category && form.setFieldValue('type', undefined)

        if (category === 'tools') {
            setTypes([
                {
                    label: '设计工具',
                    value: 1
                },
                {
                    label: '研发工具',
                    value: 2
                },
                {
                    label: '格式工具',
                    value: 3
                }
            ])
        } else if (category === 'recommend') {
            setTypes([
                {
                    label: '书籍推荐',
                    value: 1
                },
                {
                    label: '影视推荐',
                    value: 2
                }
            ])
        } else if (category === 'case') {
            setTypes([
                {
                    label: '小程序',
                    value: 1
                },
                {
                    label: 'App',
                    value: 2
                },
                {
                    label: '网站',
                    value: 3
                },
                {
                    label: '脚本',
                    value: 4
                },
                {
                    label: '桌面应用',
                    value: 5
                }
            ])
        } else {
            setTypes([])
        }
    }

    const id = form.getFieldValue('id')

    return (
        <>
            <Modal
                title={id ? `收录编辑(${form.getFieldValue('id')})` : '新建收录'}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOk={async () => {
                    try {
                        await form.validateFields()
                        form.submit()
                    } catch {}
                }}>
                <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
                    <Form.Item label="标题" name="title" rules={[{ required: true, message: '请输入标题!' }]}>
                        <Input placeholder="请输入标题" />
                    </Form.Item>
                    <Form.Item label="描述" name="desc" rules={[{ required: true, message: '请输入描述!' }]}>
                        <Input placeholder="请输入描述(部分栏目不展示描述)" />
                    </Form.Item>
                    <Form.Item label="栏目" name="category" rules={[{ required: true, message: '请选择栏目!' }]}>
                        <Select placeholder="请选择栏目" allowClear disabled={!!id}>
                            <Select.Option value={'case'}>案例展示</Select.Option>
                            <Select.Option value={'inspiration'}>灵感碎片</Select.Option>
                            <Select.Option value={'tools'}>各种工具</Select.Option>
                            <Select.Option value={'study'}>学习资料</Select.Option>
                            <Select.Option value={'recommend'}>个人推荐</Select.Option>
                        </Select>
                    </Form.Item>
                    {types.length > 0 && (
                        <Form.Item label="类目" name="type" rules={[{ required: true, message: '请选择栏目!' }]}>
                            <Select placeholder="请选择类目" allowClear disabled={!!id}>
                                {types.map((e) => (
                                    <Select.Option value={e.value}>{e.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item label="图标" name="image" rules={[{ required: true, message: '请上传!' }]}>
                        <CUpdate />
                    </Form.Item>
                    <Form.Item label="跳转链接" name="url" rules={[{ required: true, message: '请输入跳转链接!' }]}>
                        <Input placeholder="请输入跳转链接" />
                    </Form.Item>
                </Form>
            </Modal>
            {contextHolder}
        </>
    )
})
export default IndexDetail
