import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { useNavigate } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'

import items from '../menu'

import styles from '../index.module.scss'

const { Sider } = Layout

const Components = () => {
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false)

    const onClick = (e) => {
        navigate(e.keyPath.reverse().join('/'))
    }

    return (
        <>
            <Sider className={styles.sider} width={240} collapsed={collapsed}>
                <Menu className={styles.menu} onClick={onClick} mode="inline" items={items} />

                <div className={styles.collapsed} onClick={() => setCollapsed(!collapsed)}>
                    <RightOutlined className={`${styles.siderIcon} ${collapsed ? '' : styles.rotated}`} />
                </div>
            </Sider>
        </>
    )
}

export default Components
