import { createBrowserRouter } from 'react-router-dom'

import Layout from 'layout'

import Login from 'pages/login'
import Signin from 'pages/signin'
import Forget from 'pages/forget'
import Welcome from 'pages/welcome'

import IndexInclude from 'pages/index/include'
import AccountAudit from 'pages/account/audit'
import AccountInfo from 'pages/account/info'

import Merchants from 'pages/merchants'

const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/signin',
        element: <Signin />
    },
    {
        path: '/forget',
        element: <Forget />
    },
    {
        Component: Layout,
        children: [
            {
                index: true,
                path: '/',
                element: <Welcome />
            },
            {
                path: '/account/audit',
                element: <AccountAudit />
            },
            {
                path: '/account/info',
                element: <AccountInfo />
            },
            {
                path: '/index/include',
                element: <IndexInclude />
            },
            {
                path: '/merchants',
                element: <Merchants />
            }
        ]
    }
])

export default router
